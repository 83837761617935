import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import BaseFunctions, { defaultClubCaptainGroupId, defaultClubManagerGroupId } from '@/components/Utility/Base'
import CommonFunctions, { countryList } from '@/components/Utility/Common'
import UsersDataService from '@/services/UsersDataService'
import MembersDataService from '@/services/MembersDataService'
import ByPostDataService from '@/services/ByPostDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { UserGroups } from '@/services/UserGroupDataService'
import ForeignPlayersDataService from '@/services/ForeignPlayersDataService'
import TeamsDataService from '@/services/TeamsDataService'
import { History } from '@/services/HistoryDataService'
import SysUser from '@/types/SysUser'
import SysMember, { ClubRoleType, SharePermissionType } from '@/types/SysMember'
import ByPost from '@/types/ByPost'
import SysClub from '@/types/SysClub'
import SysUserGroup from '@/types/SysUserGroup'
import SysForeignPlayer from '@/types/SysForeignPlayer'
import SysTeam from '@/types/SysTeam'
import exportFromJSON from 'export-from-json'
import { VueCsvErrors, VueCsvImport, VueCsvInput, VueCsvMap, VueCsvToggleHeaders } from 'vue-csv-import'
import axios from 'axios'

/* export default defineComponent({
  name: 'AdminUsers',
  setup () {
    // component setup
  }
}) */

type medlemDataType = { brugerFornavn: string; brugerEfternavn: string; brugerBrugernavn: string; originalBrugerBrugernavn: string; brugerPassword: string; brugerTelefon: string; brugerEmail: string; medlemFoedselsdag: string; medlemVejnummer: string; medlemPostnummer: string; medlemBy: string; paradartspillerCheck: boolean; medlemKoen: string; medlemStatus: boolean; medlemLicens: number; medlemBegyndelse: string; medlemKarantaeneDato: string; medlemKarantaeneSlutdato: string; medlemHold: { name: string, id: string }, medlemLicensUdlober: string; }
type foreignPlayerDataType = { foreignPlayerStatus: boolean; foreignPlayerGender: string; foreignPlayerBirthdate: string; foreignPlayerFirstName: string; foreignPlayerLastName: string; foreignPlayerCountry: string; foreignPlayerParadart: boolean; foreignPlayerCreated: string; foreignPlayerUpdated: string; foreignPlayerMail: string; }
type memberImportCSVType = { status: string; adress?: string; postal?: number; birthday: string; licensnummer: number; medlem_licens: string; medlem_klubrolle: string; user_id?: number; koen: number; klub_navn: number }
type userImportCSVType = { status: string; brugernavn: string; kode: string; email: string; fornavn: string; efternavn: string; phone?: string; brugergruppeID: number; }
type dataReturnType = { medlemers: any; medlemData: medlemDataType; memberSearchInputValue: string; memberSearchTerm: string; exportMembers: any; columnName: any; columnNameForeign: any; tempLicens: number; userGroups: any; clubs: any; foreignPlayerData: foreignPlayerDataType; foreignPlayers: any; foreignSearchInputValue: string; foreignSearchTerm: string; customLicenseEndDate: boolean; tabValue: string; totalPages: number; totalPagesForeign: number; index: number; error: any; }

@Options({
  props: {
    msg: String
  },
  components: {
    VueCsvImport,
    VueCsvToggleHeaders,
    VueCsvMap,
    VueCsvInput,
    VueCsvErrors
  }
})
export default class AdminMembers extends Vue {
  msg!: string
  private brugers: SysUser[] = []
  private medlemers: SysMember[] = []
  private foreignPlayers: SysForeignPlayer[] = []
  private tempForeignPlayer = {} as SysForeignPlayer
  private clubs: SysClub[] = []
  private tempMedlem = {} as SysMember
  private tempTeams: SysTeam[] = []
  private bypost: ByPost[] = []
  private userGroups: SysUserGroup[] = []
  private exportMembers: [] = []
  private repeatPassword = ''
  private deleteMemberId = 0
  private deleteMemberName = ''
  private deleteForeignPlayerId = 0
  private deleteForeignPlayerName = ''
  foreignPlayerData: foreignPlayerDataType = { foreignPlayerStatus: true, foreignPlayerGender: 'Vælg her', foreignPlayerBirthdate: '', foreignPlayerFirstName: '', foreignPlayerLastName: '', foreignPlayerCountry: '', foreignPlayerParadart: false, foreignPlayerCreated: '', foreignPlayerUpdated: '', foreignPlayerMail: '' }
  medlemData: medlemDataType = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', medlemFoedselsdag: '', medlemVejnummer: '', medlemPostnummer: '', medlemBy: '', paradartspillerCheck: false, medlemKoen: 'Vælg her', medlemStatus: false, medlemLicens: 0, medlemBegyndelse: '', medlemKarantaeneDato: '', medlemKarantaeneSlutdato: '', medlemHold: { name: '', id: '' }, medlemLicensUdlober: '' }
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktiv licens', 'Ingen licens']
  clubStringValue = { name: 'Gæsteklubben', value: '22' }
  clubStringOptions: { name: string; value: string }[] = []
  userGroupStringValue = { name: 'Klubmedlemmer', id: '5' }
  userGroupStringOptions: { name: string; id: string}[] = []
  // userGroupFilterValue = { name: 'Vis alt', id: '0' }
  // userGroupFilterOptions: { name: string; id: string}[] = [{ name: 'Vis alt', id: '0' }]
  clubRoleFilterValue = 'Vis alt'
  clubRoleFilterOptions: string[] = ['Vis alt']
  clubRoleStringValue = ClubRoleType.Medlem
  clubRoleStringOptions = Object.values(ClubRoleType).filter(value => typeof value === 'string').filter(function (element) { return (<string>element).startsWith((<string>element).substr(0, 1).toLowerCase()) })
  // clubRoleStringOptions = Object.keys(ClubRoleType).map(key => ClubRoleType[<any>key]).filter(value => typeof value === 'string')
  sharePermissionsValue = this.parsePermissionEnum(SharePermissionType.Nej)
  sharePermissionsOptions = Object.values(SharePermissionType).filter(element => (<string>element).startsWith((<string>element).substring(0, 1).toUpperCase()))
  teamsStringOptions: { name: string; id: string}[] = []
  koenStringValue = 'Vælg her'
  koenStringValueForeign = 'Vælg her'
  koenStringOptions: string[] = ['Vælg her', 'Dame', 'Herre']
  countryStringValue = 'Vælg her'
  countryListOptions = countryList.map(function (item) { return item.name })
  memberSearchInputValue = ''
  memberSearchTerm = ''
  foreignSearchInputValue = ''
  foreignSearchTerm = ''
  toggleIconsSortingValue = 0
  private oldSort = ''
  private oldSortForeign = ''
  lastCreatedUserId = 0
  lastCreatedMemberId = 0
  tempLicens = 0
  private submitted = false
  private editMemberModal = false
  private warningModal = false
  private deleteForeignPlayerWarningModal = false
  private editForeignPlayerModal = false
  private lastEditedMedlemId = 0
  // private currentListSortingOrder = ''
  private lastEditedForeignId = 0
  private currentListSortingOrder = 'updated_at:desc'
  private currentListSortingOrderForeign = 'updated_at:desc'
  private currentByPostId = 0
  private inhibitUpdateCityInfo = false
  private medlemByPostnummerInputFocus = 0 // Used to track which watch-tied input fields has the current input focus. Value 1 = city input field is in focus, value 2 = postal code input field is in focus
  private todaysDate = new Date().toISOString().split('T')[0]
  private customLicenseEndDate = false
  private newLicensEndDate = this.todaysDate
  private tabValue = 'Vis og rediger medlemmer'
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  private currentPageForeign = 1
  private totalPagesForeign = 0
  private totalNumberOfPagesForeign = 0
  private extraParameter = ''
  private extraParameter2 = ''
  private extraParameterForeign = ''
  private columnName: string[] = ['licenseStatus', 'licenseNumber', 'name', 'email', 'telephone', 'club']
  private columnNameForeign: string[] = ['name', 'country', 'email']
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  pageSizeValueForeign = 25
  pageSizeValueStringForeign = '25'
  pageSizeOptionsForeign: string[] = ['10', '25', '100']
  exportClubFilterValue = { name: 'Alle klubber', id: '0' }
  exportClubFilterOptions: { name: string, id: string }[] = [{ name: 'Alle klubber', id: '0' }]
  exportStatusFilterValue = { name: 'Alle medlemer', value: '' }
  exportRoleFilterValue = 'Vis alt'
  exportStatusFilterOptions: { name: string, value: string }[] = [{ name: 'Alle medlemmer', value: '' }, { name: 'Aktiv licens', value: 'medlem_status=true' }, { name: 'Ingen licens', value: 'medlem_status=false' }]
  private exportDropDown = false
  private exportFieldsFilterValue: { name: string, value: string, id: string }[] = []
  private exportFieldsFilterOptions: { name: string, value: string, id: string }[] = [
    { name: 'Addresse', value: 'medlem_vejnummer', id: 'id' },
    { name: 'Begyndt', value: 'medlem_begyndelse', id: 'id' },
    { name: 'Brugernavn', value: 'user_id.username', id: 'id' },
    { name: 'By', value: 'bypost_id.bypost_by', id: 'id' },
    { name: 'Email', value: 'user_id.email', id: 'id' },
    { name: 'Fødselsdag', value: 'medlem_foedselsdag', id: 'id' },
    { name: 'Navn', value: 'user_id.lastname', id: 'id' },
    { name: 'Klub', value: 'klubber_id.klubber_klubnavn', id: 'id' },
    { name: 'Klubrolle', value: 'medlem_klubrolle', id: 'id' },
    { name: 'Køn', value: 'koen_id.Koen', id: 'id' },
    { name: 'Licensnummer', value: 'medlem_licens', id: 'id' },
    { name: 'Paradart', value: 'medlem_paradart', id: 'id' },
    { name: 'Postnummer', value: 'bypost_id.bypost_postnummer', id: 'id' },
    { name: 'Sideste kamp', value: 'medlem_sidste_kamp', id: 'id' },
    { name: 'Telefon', value: 'user_id.phone', id: 'id' },
    { name: 'Klubansvarlig', value: defaultClubManagerGroupId.toString(), id: 'id' },
    { name: 'Holdkaptajn', value: defaultClubCaptainGroupId.toString(), id: 'id' }
  ]

  private userNameAvailable: boolean | null = null
  private index = 0
  createMemberWithLicense = true
  newPassword = false
  newPasswordString = ''
  importMembersModal = false
  csvUsers: userImportCSVType[] = []
  csvMembers: memberImportCSVType[] = []
  csvUserHasHeader = false
  csvMemberHasHeader = false
  error: any = null

  readonly name : string = 'AdminMembers'
  $Message: any
  $Notification: any

  data (): dataReturnType {
    return {
      medlemers: this.medlemers,
      medlemData: this.medlemData,
      memberSearchInputValue: this.memberSearchInputValue,
      memberSearchTerm: this.memberSearchTerm,
      exportMembers: this.exportMembers,
      columnName: this.columnName,
      columnNameForeign: this.columnNameForeign,
      tempLicens: this.tempLicens,
      userGroups: this.userGroups,
      clubs: this.clubs,
      foreignPlayerData: this.foreignPlayerData,
      foreignPlayers: this.foreignPlayers,
      foreignSearchInputValue: this.foreignSearchInputValue,
      foreignSearchTerm: this.foreignSearchTerm,
      customLicenseEndDate: this.customLicenseEndDate,
      tabValue: 'Vis og rediger medlemmer',
      totalPages: this.totalPages,
      totalPagesForeign: this.totalPagesForeign,
      index: this.index,
      error: this.error
    }
  }

  public log () : void {
    console.log(this.csvUsers)
    console.log(this.csvMembers)
  }

  public userCreateTaskDelay () {
    return new Promise((resolve) => setTimeout(resolve, 6000))
  }

  public async updateMemberRights () : Promise<void> {
    // Fetch all active members from API
    let members: SysMember[] = []

    try {
      members = (await MembersDataService.getAll('', null, 'medlem_status=true&medlem_licens_slut_gte=2023-12-30')).data
    } catch (err) {
      console.log(err)
    }

    console.log(members)

    for (let i = 0; i < members.length; i++) {
      if (members[i].user_id.usrgroup === defaultClubCaptainGroupId || members[i].user_id.usrgroup === defaultClubManagerGroupId) {
        await this.userCreateTaskDelay()
        const updateUser = {
          role: '3'
        }

        UsersDataService.update(Number(members[i].user_id.id).toString(), updateUser)
          .then((response) => {
            console.log(response)
            console.log(members[i].user_id.username + ' have been updated: ' + response.statusText)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  // public async importMembers () : Promise<void> {
  //   if (this.csvMembers.length !== this.csvUsers.length) {
  //     this.$Message.danger({ text: 'Error Arrays not equal length' })
  //     return
  //   }
  //   console.log(this.csvUsers)

  //   const emailsList: string[] = []

  //   for (let i = 0; i < this.csvUsers.length; i++) {
  //     await this.userCreateTaskDelay()

  //     let fixedupUniqueEmail = this.csvUsers[i].email.trim()

  //     // console.log('[importMembers] emailslist = ' + JSON.stringify(emailsList))
  //     console.log('[importMembers] email found index = ' + emailsList.indexOf(this.csvUsers[i].email.trim().toLowerCase()))

  //     if (emailsList.indexOf(this.csvUsers[i].email.trim().toLowerCase()) >= 0) {
  //       if (this.csvUsers[i].email.includes('@')) {
  //         const emailParts = this.csvUsers[i].email.trim().split('@')
  //         fixedupUniqueEmail = emailParts[0] + '+' + i.toString() + '@' + emailParts[1]
  //       }
  //     } else {
  //       emailsList.push(this.csvUsers[i].email.trim())
  //     }

  //     // First create the users
  //     const createUser = {
  //       username: this.csvUsers[i].brugernavn,
  //       email: fixedupUniqueEmail,
  //       password: this.csvUsers[i].kode,
  //       provider: 'local', // Not sure of value
  //       // resetPasswordToken: '', // Not sure of value
  //       // confirmationToken: '', // Not sure of value
  //       role: '4',
  //       confirmed: true,
  //       blocked: false,
  //       usrgroup: this.csvUsers[i].brugergruppeID,
  //       firstname: this.csvUsers[i].fornavn,
  //       lastname: this.csvUsers[i].efternavn,
  //       phone: Number(this.csvUsers[i].phone?.toString().replace(/\s/g, ''))
  //     }

  //     const dateParts = this.csvMembers[i].birthday.trim().split('/')

  //     console.log('[importMembers] csvMembers[i] = ' + JSON.stringify(this.csvMembers[i]))
  //     console.log('[importMembers] Birthday date: ' + (new Date(+Number(dateParts[0]), Number(dateParts[1]) - 1, +Number(dateParts[2]), +12, +0, +0)).toISOString().substring(0, 10))

  //     try {
  //       // axios.post('https://dart-ddu.dk:1337/auth/local/register', createUser)
  //       const response = await UsersDataService.asyncCreate(createUser)
  //       // .then((response) => {
  //       console.log(response.data)
  //       const userId = Number(response.data.user.id)

  //       console.log(response.data)

  //       const createMember = {
  //         medlem_status: (this.csvMembers[i].status === 'SAND'),
  //         medlem_vejnummer: this.csvMembers[i].adress?.toString(),
  //         medlem_foedselsdag: (new Date(+Number(dateParts[0]), Number(dateParts[1]) - 1, +Number(dateParts[2]), +12, +0, +0)).toISOString().substring(0, 10),
  //         medlem_begyndelse: new Date().toISOString().split('T')[0],
  //         // medlem_rolle: null,
  //         medlem_licens: this.csvMembers[i].licensnummer,
  //         medlem_paradart: false,
  //         bypost_id: '1715', // All Membes get postal number in Rønne
  //         // user_id: userId,
  //         koen_id: this.csvMembers[i].koen,
  //         klubber_id: this.csvMembers[i].klub_navn,
  //         medlem_klubrolle: 'medlem',
  //         medlem_licens_slut: this.csvMembers[i].medlem_licens,
  //         medlem_visoplysninger: 'nej',
  //         medlem_karantaene: false,
  //         user_id: userId.toString(),
  //         bruger_id: '3'
  //       }

  //       console.log('[importMembers] createMember = ' + JSON.stringify(createMember))

  //       MembersDataService.create(createMember)
  //         .then((response) => {
  //           const tempMember = response.data

  //           console.log(tempMember.user_id.username + ' created: ' + response.statusText)
  //         })
  //         .catch((err) => {
  //           console.log('Error at MemberIndex: ' + i)
  //           console.log(err)
  //         })
  //       // })
  //     } catch (err) {
  //       console.log('Error at UserIndex: ' + i)
  //       console.log(err)
  //     } // )
  //   }
  // }

  public generateNewPassword () : void {
    this.newPasswordString = CommonFunctions.generatePassword()
    this.newPassword = true
  }

  public cancelNewPassword () : void {
    this.newPasswordString = ''
    this.newPassword = false
  }

  public updatePassword () : void {
    const updatePassword = {
      bruger_password: this.newPasswordString
    }

    UsersDataService.update(Number(this.tempMedlem.user_id.id).toString(), updatePassword)
      .then((response) => {
        console.log('Password updated: ' + response.statusText)
        this.newPasswordString = ''
        this.newPassword = false
        this.$Message.success({ text: 'Adgangskode er blevet opdateret' })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public newMemberLicenseEndData (createWithLicense: boolean) : null | string {
    if (!createWithLicense) {
      return null
    }

    const currentYear: number = new Date(this.todaysDate).getFullYear()
    const seasonEnd = currentYear + '-12-01'

    if (this.todaysDate > seasonEnd) {
      return (currentYear + 1).toString() + '-12-31'
    }

    return currentYear.toString() + '-12-31'
  }

  public checkUserName () : void {
    let users: SysUser[] = []

    if (!(this.medlemData.brugerBrugernavn.trim().length > 1)) {
      return this.$Message.warning({ text: 'Brugernavnet er for kort' })
    }

    UsersDataService.getAll('', null, `username=${this.medlemData.brugerBrugernavn}`)
      .then((response) => {
        users = response.data

        if (users.length > 0) {
          this.userNameAvailable = false
          this.$Notification.warning({
            title: 'Brugernavner er allerede taget'
          })
        } else {
          this.userNameAvailable = true
          this.$Notification.success({
            title: 'Brugernavnet er ledigt'
          })
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public parsePermissionEnum (typeString: any) : SharePermissionType {
    return <any>SharePermissionType[typeString]
  }

  @Watch('deleteForeignPlayerWarningModal')
  onDeleteForeignPlayerWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteForeignPlayerId = 0
      this.deleteForeignPlayerName = ''
    }
  }

  @Watch('exportDropDown')
  onExportDropDownToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.exportClubFilterValue = { name: 'Alle klubber', id: '0' }
      this.exportStatusFilterValue = { name: 'Alle medlemer', value: '' }
      this.exportFieldsFilterValue = []
      this.exportRoleFilterValue = 'Vis alt'
    }
  }

  @Watch('newLicensEndData')
  onChange () : void {
    console.log(this.newLicensEndDate)
  }

  //  @Watch('userGroupFilterValue')
  //  onUserGroupFilterValueChange () {
  //    if (this.userGroupFilterValue.id !== '0') {
  //      this.extraParameter = 'bruger_id.bruger_brugergruppe_id=' + this.userGroupFilterValue.id
  //    } else {
  //      this.extraParameter = ''
  //    }
  //    this.retrieveMembers()
  //  }

  @Watch('clubRoleFilterValue')
  onClubRoleFilterValueChange () : void {
    if (this.clubRoleFilterValue !== 'Vis alt') {
      let tempVal = this.clubRoleFilterValue.toLowerCase().replaceAll(' ', '_')

      if (tempVal.startsWith('æ')) {
        tempVal = tempVal.replaceAll('æ', 'ae')
      }

      this.extraParameter2 = 'medlem_klubrolle=' + tempVal
    } else {
      this.extraParameter2 = ''
    }

    this.retrieveMembers()
    CommonFunctions.scrollToTop()
  }

  @Watch('filterStatusStringValue')
  onMemberStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveMembers()
    CommonFunctions.scrollToTop()
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveMembers()
    CommonFunctions.scrollToTop()
  }

  @Watch('pageSizeValueStringForeign')
  onForeignPageSizeValueChange (newVal: string) : void {
    this.pageSizeValueForeign = Number(newVal)
    this.currentPageForeign = 1
    this.retrieveForeignPlayers()
    CommonFunctions.scrollToTop()
  }

  public licenseExpires (date : string) : string {
    let retVal = date

    if (retVal === null) {
      retVal = '1970-01-01'
    }

    return this.toDanishDateString(retVal)
  }

  public renewLicens (currentLicensEndDate: string) : void {
    const currentYear: number = new Date().getFullYear()
    let licensEndDate = ''

    if (this.customLicenseEndDate) {
      licensEndDate = this.newLicensEndDate
    } else if (new Date(currentLicensEndDate) < new Date(new Date().getFullYear() + '-12-01') && new Date(this.todaysDate) < new Date(new Date().getFullYear() + '-12-01')) {
      licensEndDate = currentYear.toString() + '-12-31'
    } else {
      licensEndDate = (currentYear + 1).toString() + '-12-31'
    }

    const updateMember = {
      medlem_licens_slut: licensEndDate
    }

    MembersDataService.update(this.lastEditedMedlemId.toString(), updateMember)
      .then((response) => {
        console.log(response.data)

        const createMemberHistory = {
          medlemshistorik_handling: 'Licens fornyelse til ' + this.toDanishDateString(licensEndDate),
          medlem_id: this.lastEditedMedlemId
        }

        History.HistoryDataService.create(createMemberHistory)
          .then((response) => {
            console.log(response.data)
            this.editMember(this.lastEditedMedlemId)
            this.customLicenseEndDate = false
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public activeLicense (date: string) : boolean {
    if (this.todaysDate > date || date === null) {
      return false
    }

    return true
  }

  public toDanishDateString (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 2)
  }

  public disableLicenseRenewalBtn (date: string) : boolean {
    const renewalOpen = new Date(new Date().getFullYear() + '-12-01').toISOString()

    if (date === '1970-01-01' || Date.parse(this.todaysDate) > Date.parse(date) || Date.parse(this.todaysDate) >= Date.parse(renewalOpen) || new Date(date) < new Date(new Date().getFullYear() + '-12-31')) {
      return false
    }

    return true
  }

  public testActiveQuarantine (startDate: string, endDate: string) : boolean {
    const d = new Date().toISOString()

    if (startDate !== null && endDate !== null) {
      if (new Date(startDate).toISOString() < d && new Date(endDate).toISOString() > d) {
        return true
      }
    }

    return false
  }

  public getUserGroupFromId (userGroupId: number) : string {
    const retVal = this.userGroups.find(x => x.id === userGroupId)?.brugergruppe_gruppenavn

    return (retVal === undefined ? 'Ingen' : retVal)
  }

  public showColumn (column: string) : boolean {
    return this.columnName.includes(column)
  }

  public showColumnForeign (column: string) : boolean {
    return this.columnNameForeign.includes(column)
  }

  public convertClubRoleEnumToArray () : void {
    const tempArray = Object
      .values(ClubRoleType)
      .filter(function (element) { return (<string>element).startsWith((<string>element).substr(0, 1).toLowerCase()) })
      .toString()
      .replaceAll('_', ' ')
      .split(',')

    for (const item of tempArray) {
      if (!item.trim().startsWith('ae')) {
        this.clubRoleFilterOptions.push(this.capitalize(item))
      } else {
        this.clubRoleFilterOptions.push(this.capitalize(item).replace('Ae', 'Æ'))
      }
    }
  }

  public capitalize (str: string) : string {
    const retVal = str.toLowerCase()

    return str.charAt(0).toUpperCase() + retVal.slice(1)
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMembers()
  }

  public turnToNewPageForeign (pageChange : number) : void {
    this.currentPageForeign += pageChange

    if (this.currentPageForeign < 1) {
      this.currentPageForeign = 1
    } else if (this.currentPageForeign >= this.totalNumberOfPagesForeign) {
      this.currentPageForeign = this.totalNumberOfPagesForeign
    }

    this.retrieveForeignPlayers()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMembers()
  }

  public jumpToPageForeign (pageNumber : number) : void {
    this.currentPageForeign = pageNumber

    if (this.currentPageForeign < 1) {
      this.currentPageForeign = 1
    } else if (this.currentPageForeign > this.totalNumberOfPagesForeign) {
      this.currentPageForeign = this.totalNumberOfPagesForeign
    }

    this.retrieveForeignPlayers()
  }

  public passwordTest (passwordToBeTested: string) : boolean {
    return CommonFunctions.strongPasswordTest(passwordToBeTested)
  }

  public columnSortForeign (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrderForeign = 'updated_at:desc'
      this.oldSortForeign = ''
      this.currentPageForeign = 1
      this.foreignSearchInputValue = ''
      this.foreignSearchTerm = ''
    }
    if (sortBy === 'navn') {
      if (this.oldSortForeign === '' || this.oldSortForeign !== sortBy) {
        this.currentListSortingOrderForeign = 'ud_spiller_efternavn:asc'
        this.oldSortForeign = sortBy
      } else {
        this.currentListSortingOrderForeign = 'ud_spiller_efternavn:desc'
        this.oldSortForeign = ''
      }
    }
    if (sortBy === 'foedselsdag') {
      if (this.oldSortForeign === '' || this.oldSortForeign !== sortBy) {
        this.currentListSortingOrderForeign = 'ud_foedselsdag:asc'
        this.oldSortForeign = sortBy
      } else {
        this.currentListSortingOrderForeign = 'ud_foedselsdag:desc'
        this.oldSortForeign = ''
      }
    }
    if (sortBy === 'koen') {
      if (this.oldSortForeign === '' || this.oldSortForeign !== sortBy) {
        this.currentListSortingOrderForeign = 'koen_id.Koen:asc'
        this.oldSortForeign = sortBy
      } else {
        this.currentListSortingOrderForeign = 'koen_id.Koen:desc'
        this.oldSortForeign = ''
      }
    }
    if (sortBy === 'land') {
      if (this.oldSortForeign === '' || this.oldSortForeign !== sortBy) {
        this.currentListSortingOrderForeign = 'ud_spiller_land:asc'
        this.oldSortForeign = sortBy
      } else {
        this.currentListSortingOrderForeign = 'ud_spiller_land:desc'
        this.oldSortForeign = ''
      }
    }
    if (sortBy === 'paradart') {
      if (this.oldSortForeign === '' || this.oldSortForeign !== sortBy) {
        this.currentListSortingOrderForeign = 'ud_spiller_paradart:asc'
        this.oldSortForeign = sortBy
      } else {
        this.currentListSortingOrderForeign = 'ud_spiller_paradart:desc'
        this.oldSortForeign = ''
      }
    }
    if (sortBy === 'email') {
      if (this.oldSortForeign === '' || this.oldSortForeign !== sortBy) {
        this.currentListSortingOrderForeign = 'ud_spiller_mail:asc'
        this.oldSortForeign = sortBy
      } else {
        this.currentListSortingOrderForeign = 'ud_spiller_mail:desc'
        this.oldSortForeign = ''
      }
    }
    if (sortBy === 'oprettet') {
      if (this.oldSortForeign === '' || this.oldSortForeign !== sortBy) {
        this.currentListSortingOrderForeign = 'created_at:asc'
        this.oldSortForeign = sortBy
      } else {
        this.currentListSortingOrderForeign = 'created_at:desc'
        this.oldSortForeign = ''
      }
    }

    this.retrieveForeignPlayers()
    CommonFunctions.scrollToTop()
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'updated_at:desc'
      this.oldSort = ''
      this.currentPage = 1
      this.memberSearchInputValue = ''
      this.memberSearchTerm = ''
      this.clubRoleFilterValue = 'Vis alt'
      // this.userGroupFilterValue = { name: 'Vis alle', id: '0' }
      this.filterStatusStringValue = 'Vis alt'
    }
    if (sortBy === 'licens') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_licens:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_licens:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'status') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_status:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_status:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'navn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'user_id.lastname:asc,user_id.firstname:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'user_id.lastname:desc,user_id.firstname:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'koen') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'koen_id.Koen:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'koen_id.Koen:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'paradart') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_paradart:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_paradart:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'email') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'user_id.email:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'user_id.email:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'telefon') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'user_id.phone:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'user_id.phone:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'klub') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_id.klubber_klubnavn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_id.klubber_klubnavn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'karantaene') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_karantaene_slutdato:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_karantaene_slutdato:asc'
        this.oldSort = ''
      }
    }

    this.retrieveMembers()
    CommonFunctions.scrollToTop()
  }

  @Watch('warningModal')
  onWarningModalToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteMemberId = 0
      this.deleteMemberName = ''
    }
  }

  @Watch('editMemberModal')
  onEditMemberModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.medlemData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', medlemFoedselsdag: '', medlemVejnummer: '', medlemPostnummer: '', medlemBy: '', paradartspillerCheck: false, medlemKoen: 'Vælg her', medlemStatus: false, medlemLicens: 0, medlemBegyndelse: '', medlemKarantaeneDato: '', medlemKarantaeneSlutdato: '', medlemHold: { name: '', id: '' }, medlemLicensUdlober: '' }
      this.clubRoleStringValue = ClubRoleType.Medlem
      this.repeatPassword = ''
    }
  }

  @Watch('medlemData.medlemBy')
  onMedlemByFieldChanged (fieldByValue: string, oldFieldByValue: string) : void {
    console.log('Field value changed to: ' + fieldByValue)

    if (this.medlemByPostnummerInputFocus === 1 && fieldByValue !== null) {
      this.updatePostalCodeInputFieldDataFromCityInputFieldData(fieldByValue)
    }
  }

  @Watch('medlemData.medlemPostnummer')
  onMedlemPostnummerFieldChanged (fieldPostnummerValue: string, oldFieldPostnummerValue: string) : void {
    console.log('Field value changed to: ' + fieldPostnummerValue)

    if (this.medlemByPostnummerInputFocus === 2 && this.inhibitUpdateCityInfo === false && !isNaN(Number(fieldPostnummerValue))) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(fieldPostnummerValue)
    }
  }

  @Watch('medlemByPostnummerInputFocus')
  onMedlemByPostnummerInputFocusChanged (fieldInputFocusValue: string, oldInputFocusValue: string) : void {
    console.log('Input field focus value changed to: ' + fieldInputFocusValue)

    if (Number(fieldInputFocusValue) === 1) {
      if (!isNaN(Number(this.medlemData.medlemPostnummer))) {
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.medlemData.medlemPostnummer)
      }
    } else if (Number(fieldInputFocusValue) === 2) {
      if (this.medlemData.medlemBy !== null) {
        this.updatePostalCodeInputFieldDataFromCityInputFieldData(this.medlemData.medlemBy)
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.medlemData.medlemPostnummer)
      }
    }
  }

  public updatePostalCodeInputFieldDataFromCityInputFieldData (cityData: string) : void {
    if (cityData !== undefined && cityData !== null && cityData.length > 1) {
      ByPostDataService.findByCity(cityData, '', '1')
        .then((response) => {
          this.bypost = response.data
          if (this.bypost === undefined || this.bypost === null || this.bypost[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.inhibitUpdateCityInfo = true
            this.currentByPostId = (this.bypost[0].id !== null ? Number(this.bypost[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.medlemData.medlemPostnummer = (this.bypost[0].id !== null ? (this.bypost[0].bypost_postnummer.toString()) : '')
            this.inhibitUpdateCityInfo = false
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          this.bypost = response.data
          if (this.bypost === undefined || this.bypost === null || this.bypost[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.currentByPostId = (this.bypost[0].id !== null ? Number(this.bypost[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.medlemData.medlemBy = (this.bypost[0].id !== null ? (this.bypost[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public forceRerenderMembersList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
    // console.log('Testing: ' + this.medlemers[6].user_id.bruger_fornavn)
    // console.log('Testing: ' + this.medlemers[6].user_id.bruger_efternavn)
  }

  public forceRerenderForeignPlayersList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public findMember () : void {
    console.log('Search member.')
    this.memberSearchTerm = this.memberSearchInputValue
    this.retrieveMembers()
    CommonFunctions.scrollToTop()
  }

  public findForeign () : void {
    console.log('Search foreign players')
    this.foreignSearchTerm = this.foreignSearchInputValue
    this.retrieveForeignPlayers()
    CommonFunctions.scrollToTop()
  }

  public editMember (medlemId : number) : void {
    console.log('Edit member with Id: ' + medlemId)
    this.lastEditedMedlemId = medlemId

    MembersDataService.get(medlemId.toString())
      .then((response) => {
        this.tempMedlem = response.data
        console.log(response.data)

        this.medlemData = {
          brugerFornavn: this.tempMedlem.user_id.firstname,
          brugerEfternavn: this.tempMedlem.user_id.lastname,
          brugerBrugernavn: this.tempMedlem.user_id.username,
          originalBrugerBrugernavn: this.tempMedlem.user_id.username,
          brugerPassword: '',
          brugerTelefon: ((this.tempMedlem.user_id === undefined || this.tempMedlem.user_id.phone === null) ? '' : this.tempMedlem.user_id.phone.toString()),
          brugerEmail: this.tempMedlem.user_id.email,
          medlemFoedselsdag: this.tempMedlem.medlem_foedselsdag,
          medlemVejnummer: (this.tempMedlem.medlem_vejnummer === null ? '' : this.tempMedlem.medlem_vejnummer.toString()),
          medlemPostnummer: (this.tempMedlem.bypost_id !== undefined && this.tempMedlem.bypost_id !== null ? (this.tempMedlem.bypost_id.bypost_postnummer !== null ? Number(this.tempMedlem.bypost_id.bypost_postnummer).toString() : '0') : '0'),
          medlemBy: (this.tempMedlem.bypost_id !== undefined && this.tempMedlem.bypost_id !== null ? (this.tempMedlem.bypost_id.bypost_by !== null ? this.tempMedlem.bypost_id.bypost_by : '') : ''),
          paradartspillerCheck: this.tempMedlem.medlem_paradart,
          medlemKoen: this.tempMedlem.koen_id.Koen,
          medlemStatus: this.tempMedlem.medlem_status,
          medlemLicens: this.tempMedlem.medlem_licens,
          medlemBegyndelse: this.tempMedlem.medlem_begyndelse,
          medlemKarantaeneDato: this.tempMedlem.medlem_karantaene_dato,
          medlemKarantaeneSlutdato: this.tempMedlem.medlem_karantaene_slutdato,
          medlemHold: { name: (this.tempMedlem.hold_id !== undefined && this.tempMedlem.hold_id !== null && this.tempMedlem.hold_id.id !== null ? this.tempMedlem.hold_id.hold_holdnavn : 'Vælg her'), id: (this.tempMedlem.hold_id !== undefined && this.tempMedlem.hold_id !== null && this.tempMedlem.hold_id.id !== null ? Number(this.tempMedlem.hold_id.id).toString() : '0') },
          medlemLicensUdlober: (this.tempMedlem.medlem_licens_slut === null ? '1970-01-01' : this.tempMedlem.medlem_licens_slut)
        }

        if (this.tempMedlem.medlem_klubrolle === null) {
          this.clubRoleStringValue = ClubRoleType.Medlem
          // this.clubRoleStringValue = this.convertClubRoleValueEnumToString(ClubRoleType.Medlem)
        } else {
          // this.clubRoleFilterValue = this.convertClubRoleValueEnumToString(this.tempMedlem.medlem_klubrolle)
          this.clubRoleStringValue = this.tempMedlem.medlem_klubrolle
        }
        if (this.tempMedlem.klubber_id === null) {
          this.clubStringValue = { name: 'Ingen klub', value: '0' }
        } else {
          this.clubStringValue = { name: this.tempMedlem.klubber_id.klubber_klubnavn, value: Number(this.tempMedlem.klubber_id.id).toString() }
        }
        if (this.tempMedlem.user_id.usrgroup === null) {
          this.userGroupStringValue = { name: 'Ingen brugergruppe', id: '0' }
        } else {
          const userGroup = this.userGroups.find(el => el.id === this.tempMedlem.user_id.usrgroup)
          if (userGroup !== undefined) {
            this.userGroupStringValue = { id: Number(userGroup.id).toString(), name: userGroup.brugergruppe_gruppenavn }
          }
        }

        // Update, if possible, the contents of the city input field based upon the value of the postal code obtained from the last API access data result.
        if (this.tempMedlem.bypost_id !== undefined && this.tempMedlem.bypost_id !== null && this.tempMedlem.bypost_id.bypost_postnummer !== null && !isNaN(Number(this.tempMedlem.bypost_id.bypost_postnummer))) {
          this.updateCityInputFieldDataFromPostalCodeInputFieldData(Number(this.tempMedlem.bypost_id.bypost_postnummer).toString())
        }

        this.teamsStringOptions = [{ name: 'Vælg her', id: '0' }]

        TeamsDataService.getAll()
          .then((response) => {
            this.tempTeams = response.data
            console.log(response.data)

            for (const tempTeam of this.tempTeams) {
              if (tempTeam.id !== null) {
                this.teamsStringOptions.push({ name: tempTeam.hold_holdnavn, id: Number(tempTeam.id).toString() })
              }
            }

            this.editMemberModal = true
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelMemberEdit () : void {
    console.log('Cancel member edit.')
    this.medlemData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', medlemFoedselsdag: '', medlemVejnummer: '', medlemPostnummer: '', medlemBy: '', paradartspillerCheck: false, medlemKoen: 'Vælg her', medlemStatus: false, medlemLicens: 0, medlemBegyndelse: '', medlemKarantaeneDato: '', medlemKarantaeneSlutdato: '', medlemHold: { name: '', id: '' }, medlemLicensUdlober: '' }
    this.clubRoleStringValue = ClubRoleType.Medlem
    this.clubStringValue = { name: 'Gæsteklubben', value: '22' }
  }

  public deleteMemberWarning (medlemId: number, name: string, surName: string) : void {
    this.deleteMemberName = name + ' ' + surName
    this.deleteMemberId = medlemId
    this.warningModal = true
  }

  public deleteMember (medlemId : number) : void {
    console.log('Delete member with Id: ' + medlemId)
    MembersDataService.delete(medlemId.toString())
      .then((response) => {
        console.log(response.data)
        this.warningModal = false
        this.retrieveMembers()
      })
      .catch((err) => {
        this.error = err
        this.warningModal = false
        // console.log(err)
      })
  }

  public testLegalEmail (emailAddress: string) : boolean {
    return BaseFunctions.isEmail(emailAddress)
  }

  public createMember () : void {
    let tempUserNames: SysUser[] = []
    let tempMemberLicenseNumbers: SysMember[] = []
    let highestLicensNumber = 0

    const getLicenseNumber = async () => {
      await MembersDataService.getAll('medlem_licens:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 0, totalcount: 0 })
        .then((response) => {
          tempMemberLicenseNumbers = response.data
          highestLicensNumber = tempMemberLicenseNumbers[0].medlem_licens
        })
        .catch((err) => {
          this.error = err
        })
    }

    const checkLeagUserName = async (userName: string) => {
      await UsersDataService.getAll('', null, `username=${userName.trim()}`)
        .then((response) => {
          tempUserNames = response.data
        })
        .catch((err) => {
          this.error = err
        })
    }

    if (
      !(this.medlemData.brugerFornavn.length > 1) ||
      !(this.medlemData.brugerEfternavn.length > 1) ||
      !(this.medlemData.brugerBrugernavn.length > 1) ||
      !(this.passwordTest(this.medlemData.brugerPassword)) ||
      this.medlemData.brugerPassword !== this.repeatPassword ||
      !(this.testLegalEmail(this.medlemData.brugerEmail)) ||
      !(this.medlemData.medlemFoedselsdag !== '') ||
      !this.testUserGroupAndClub(Number(this.userGroupStringValue.id), Number(this.clubStringValue.value)) ||
      !(this.medlemData.medlemVejnummer.length > 1) ||
      !(this.koenStringValue !== 'Vælg her')
    ) {
      return this.$Message.warning({ text: 'Fejl: Ikke alle felter er udfyldt korrekt' })
    }

    const promises = []
    promises.push(getLicenseNumber())
    promises.push(checkLeagUserName(this.medlemData.brugerBrugernavn))

    Promise.all(promises)
      .then(() => {
        if (highestLicensNumber === 0) {
          return this.$Message.danger({ text: 'Fejl: Kunne ikke generere nyt licensnummer' })
        }
        if (tempUserNames.length > 0) {
          return this.$Message.danger({ text: 'Fejl: Brugernavnet er allerede valgt' })
        }

        const createUserData = {
          firstname: this.medlemData.brugerFornavn,
          lastname: this.medlemData.brugerEfternavn,
          username: this.medlemData.brugerBrugernavn,
          password: this.medlemData.brugerPassword,
          phone: Number(this.medlemData.brugerTelefon.trim()),
          email: this.medlemData.brugerEmail,
          usrgroup: this.userGroupStringValue.id,
          provider: 'local',
          role: '4',
          confirmed: true
        }

        // const createUserData = {
        //   bruger_fornavn: this.medlemData.brugerFornavn,
        //   bruger_efternavn: this.medlemData.brugerEfternavn,
        //   bruger_brugernavn: this.medlemData.brugerBrugernavn,
        //   bruger_password: this.medlemData.brugerPassword,
        //   bruger_telefon: this.medlemData.brugerTelefon.trim(),
        //   bruger_email: this.medlemData.brugerEmail,
        //   bruger_brugergruppe_id: this.userGroupStringValue.id
        // }

        console.log('Attempt to create new user with Id: ' + (1 + this.lastCreatedUserId))

        UsersDataService.create(createUserData)
          .then((response) => {
            this.lastCreatedUserId = response.data.id
            console.log(response.data)

            const createMemberData = {
              medlem_begyndelse: new Date(),
              medlem_foedselsdag: this.medlemData.medlemFoedselsdag,
              medlem_vejnummer: this.medlemData.medlemVejnummer,
              bypost_id: this.currentByPostId,
              medlem_paradart: this.medlemData.paradartspillerCheck,
              user_id: this.lastCreatedUserId,
              koen_id: (this.koenStringValue === 'Herre' ? 2 : 1),
              medlem_status: true,
              medlem_klubrolle: this.clubRoleStringValue,
              klubber_id: (this.clubStringValue.value === '0' ? null : this.clubStringValue.value),
              medlem_licens: highestLicensNumber + 1,
              medlem_visoplysninger: this.parsePermissionEnum(this.sharePermissionsValue),
              medlem_licens_slut: this.newMemberLicenseEndData(this.createMemberWithLicense)
            }

            console.log('Attempt to create new member with Id: ' + (1 + this.lastCreatedMemberId))

            MembersDataService.create(createMemberData)
              .then((response) => {
                this.lastCreatedMemberId = response.data.id
                console.log(response.data)

                const createHistoryEntry = {
                  medlemshistorik_handling: 'Oprettet d. ' + this.toDanishDateString(response.data.created_at),
                  medlem_id: response.data.id,
                  klubber_id: (this.clubStringValue.value === '0' ? null : this.clubStringValue.value)
                }

                History.HistoryDataService.create(createHistoryEntry)
                  .then((response) => {
                    console.log(response.data)

                    this.submitted = true
                    this.$Message.success({ text: 'Medlemmet er blevet oprettet' })
                    this.newCreateMember()
                    this.tabValue = 'Vis og rediger medlemmer'
                    CommonFunctions.scrollToTop()
                  })
                  .catch((err) => {
                    this.error = err
                    // console.log(err)
                  })
              })
              .catch((err) => {
                this.error = err
                // console.log(err)
              })
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .then((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public newCreateMember () : void {
    this.submitted = false
    this.repeatPassword = ''
    this.medlemData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', medlemFoedselsdag: '', medlemVejnummer: '', medlemPostnummer: '', medlemBy: '', paradartspillerCheck: false, medlemKoen: 'Vælg her', medlemStatus: false, medlemLicens: 0, medlemBegyndelse: '', medlemKarantaeneDato: '', medlemKarantaeneSlutdato: '', medlemHold: { name: '', id: '' }, medlemLicensUdlober: '' }
    this.clubRoleStringValue = ClubRoleType.Medlem
    this.userGroupStringValue = { name: 'Klubmedlemmer', id: '5' }
    this.clubStringValue = { name: 'Gæsteklubben', value: '22' }
    this.sharePermissionsValue = this.parsePermissionEnum(SharePermissionType.Nej)
    this.retrieveMembers()
  }

  public async updateMember () : Promise<void> {
    const tempBrugerTelefon : string = this.medlemData.brugerTelefon.toString().trim()
    const tempMemberPostnummer : string = this.medlemData.medlemPostnummer.toString().trim()

    // Perform some checks that there is relevant data in all input fields, and bail out with an appropriate error message to the end user without further processing, if any of these checks fails.
    if (!(this.medlemData.brugerFornavn.trim().length > 1) ||
      !(this.medlemData.brugerEfternavn.trim().length > 1) ||
      !(this.medlemData.brugerBrugernavn.trim().length > 1) ||
      !(tempBrugerTelefon.length > 7) ||
      Number.isNaN(tempBrugerTelefon) ||
      !(this.medlemData.brugerEmail.trim().length > 1) ||
      !BaseFunctions.isEmail(this.medlemData.brugerEmail) ||
      !(this.medlemData.medlemFoedselsdag.length > 1) ||
      !(this.medlemData.medlemVejnummer.length > 1) ||
      !(tempMemberPostnummer.length === 4) ||
      Number.isNaN(tempMemberPostnummer) ||
      !(this.medlemData.medlemBegyndelse.length > 1) ||
      !(this.medlemData.medlemKoen !== 'Vælg her') ||
      !this.testUserGroupAndClub(Number(this.userGroupStringValue.id), Number(this.clubStringValue.value))) {
      this.$Message.danger({ text: 'Fejl: Et eller flere felter er ikke udfyldt korrekt' })

      return
    }
    console.log('[updateMember()] Update information of member with Id: ' + this.lastEditedMedlemId)

    // If user attempts to update the password, do a check to ensure that the new password is strong enough and that the repeat password value is identical to the new password.
    // if (this.medlemData.brugerPassword !== '') {
    //   if (!this.passwordTest(this.medlemData.brugerPassword)) {
    //     this.$Message.danger({ text: 'Fejl: Adgangskoden er ikke stærk nok' })

    //     return
    //   }
    //   if (this.medlemData.brugerPassword !== this.repeatPassword) {
    //     this.$Message.danger({ text: 'Fejl: Adgangskoderne er ikke ens' })

    //     return
    //   }
    // }

    let tempUser: SysUser[] = []
    // If a new username have been entered, check that it is available
    if (this.medlemData.brugerBrugernavn !== this.medlemData.originalBrugerBrugernavn) {
      try {
        tempUser = (await UsersDataService.getAll('', null, `username=${this.medlemData.brugerBrugernavn}`)).data
      } catch (err) {
        this.error = err
      }
    }

    if (tempUser.length > 0) {
      this.$Message.warning({ text: 'Brugernavnet er allerede i brug, - prøv et andet' })
      return
    }

    const updateUserData = {
      firstname: this.medlemData.brugerFornavn,
      lastname: this.medlemData.brugerEfternavn,
      username: this.medlemData.brugerBrugernavn,
      phone: Number(tempBrugerTelefon),
      email: this.medlemData.brugerEmail,
      usrgroup: (this.userGroupStringValue.id === '0' ? null : this.userGroupStringValue.id)
    }
    // const updateUserDataWithPW = {
    //   firstname: this.medlemData.brugerFornavn,
    //   lastname: this.medlemData.brugerEfternavn,
    //   username: this.medlemData.brugerBrugernavn,
    //   password: this.medlemData.brugerPassword,
    //   phone: Number(tempBrugerTelefon),
    //   email: this.medlemData.brugerEmail,
    //   usrgroup: (this.userGroupStringValue.id === '0' ? null : this.userGroupStringValue.id)
    // }

    // const updateUserData = {
    //   bruger_fornavn: this.medlemData.brugerFornavn,
    //   bruger_efternavn: this.medlemData.brugerEfternavn,
    //   bruger_brugernavn: this.medlemData.brugerBrugernavn,
    //   bruger_telefon: tempBrugerTelefon,
    //   bruger_email: this.medlemData.brugerEmail,
    //   bruger_brugergruppe_id: (this.userGroupStringValue.id === '0' ? null : this.userGroupStringValue.id)
    // }
    // const updateUserDataWithPW = {
    //   bruger_fornavn: this.medlemData.brugerFornavn,
    //   bruger_efternavn: this.medlemData.brugerEfternavn,
    //   bruger_brugernavn: this.medlemData.brugerBrugernavn,
    //   bruger_password: this.medlemData.brugerPassword,
    //   bruger_telefon: tempBrugerTelefon,
    //   bruger_email: this.medlemData.brugerEmail,
    //   bruger_brugergruppe_id: (this.userGroupStringValue.id === '0' ? null : this.userGroupStringValue.id)
    // }

    const updateMemberData = {
      medlem_foedselsdag: this.medlemData.medlemFoedselsdag,
      medlem_vejnummer: this.medlemData.medlemVejnummer,
      bypost_id: this.currentByPostId,
      medlem_paradart: this.medlemData.paradartspillerCheck,
      medlem_klubrolle: this.clubRoleStringValue,
      medlem_licens: this.medlemData.medlemLicens,
      medlem_begyndelse: this.medlemData.medlemBegyndelse,
      medlem_status: this.medlemData.medlemStatus,
      koen_id: this.medlemData.medlemKoen === 'Herre' ? 2 : 1,
      klubber_id: (this.clubStringValue.value === '0' ? null : Number(this.clubStringValue.value).toString()),
      medlem_karantaene_dato: this.medlemData.medlemKarantaeneDato,
      medlem_karantaene_slutdato: this.medlemData.medlemKarantaeneSlutdato,
      hold_id: (this.medlemData.medlemHold.id === '0' ? null : Number(this.medlemData.medlemHold.id).toString())
    }

    const updateMemberPromise = MembersDataService.update(this.lastEditedMedlemId.toString(), updateMemberData)
    const updateUserPromise = UsersDataService.update(Number(this.tempMedlem.user_id.id).toString(), updateUserData)

    Promise.all([updateMemberPromise, updateUserPromise])
      .then((response) => {
        console.log('[updateMember()] MemberData updated: ' + response[0].statusText)
        console.log('[updateMember()] UserData updated: ' + response[1].statusText)
        if (tempUser.length === 0) {
          this.$Message.success({ text: 'Medlemmet er blevet opdateret' })
        }
        this.retrieveMembers()
        this.editMemberModal = false
        this.newUpdateMember()
      })
      .catch((err) => {
        this.error = err
      })
  }

  newUpdateMember () : void {
    this.medlemData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', medlemFoedselsdag: '', medlemVejnummer: '', medlemPostnummer: '', medlemBy: '', paradartspillerCheck: false, medlemKoen: 'Vælg her', medlemStatus: false, medlemLicens: 0, medlemBegyndelse: '', medlemKarantaeneDato: '', medlemKarantaeneSlutdato: '', medlemHold: { name: '', id: '' }, medlemLicensUdlober: '' }
    this.clubRoleStringValue = ClubRoleType.Medlem
    this.clubStringValue = { name: 'Gæsteklubben', value: '22' }
    this.userGroupStringValue = { name: 'Klubmedlemmer', id: '5' }
    this.repeatPassword = ''
  }

  public testUserGroupAndClub (userGroupId: number, clubId: number) : boolean {
    if ((userGroupId === 3 && clubId === 0) || (userGroupId === 3 && clubId === 22)) {
      return false
    }
    return true
  }

  public retrieveMembers () : void {
    console.log('Counting Members')
    let countFilter = ''
    if (this.filterStatusStringValue === 'Vis alt') {
      countFilter = (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2))
    } else if (this.filterStatusStringValue === 'Aktiv licens') {
      countFilter = 'medlem_status=true&' + (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2))
    } else {
      countFilter = 'medlem_status=false&' + (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2))
    }
    MembersDataService.getCount(countFilter)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })

    console.log('Fetching members data from API.')

    if (this.memberSearchTerm.length > 0) {
      MembersDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktiv licens'), (this.filterStatusStringValue === 'Vis alt'), this.memberSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2)))
        .then((response) => {
          this.medlemers = response.data
          // console.log(response.data)
          this.forceRerenderMembersList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        MembersDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2)))
          .then((response) => {
            this.medlemers = response.data
            // console.log(response.data)
            this.forceRerenderMembersList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        MembersDataService.findByUserStatus((this.filterStatusStringValue === 'Aktiv licens'), this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2)))
          .then((response) => {
            this.medlemers = response.data
            // console.log(response.data)
            this.forceRerenderMembersList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
    console.log(this.countryListOptions)
  }

  // public retrieveTempLicens () : void {
  //   console.log('Fething highest licens nummber from API')
  //   MembersDataService.getAll('medlem_licens:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 0, totalcount: 0 })
  //     .then((response) => {
  //       this.tempLicens = response.data[0].medlem_licens
  //       console.log('Highest current licensnumber ' + JSON.stringify(this.tempLicens))
  //     })
  //     .catch((err) => {
  //       this.error = err
  //     })
  // }

  public retrieveUserGroups () : void {
    console.log('Fetching active usergroups from API')
    this.userGroupStringOptions = [{ name: 'Ingen brugergruppe', id: '0' }]

    UserGroups.UserGroupDataService.getAll('', null, 'brugergruppe_status=true')
      .then((response) => {
        this.userGroups = response.data

        for (const userGroup of this.userGroups) {
          this.userGroupStringOptions.push({ name: userGroup.brugergruppe_gruppenavn, id: Number(userGroup.id).toString() })
          // this.userGroupFilterOptions.push({ name: userGroup.brugergruppe_gruppenavn, id: Number(userGroup.id).toString() })
        }
      })
  }

  public retrieveClubs () : void {
    console.log('Fetching clubs from API')
    this.exportClubFilterOptions = [{ name: 'Alle klubber', id: '0' }]
    this.clubStringOptions = [{ name: 'Ingen klub', value: '0' }]

    ClubsDataService.getAll('', null, '')
      .then((response) => {
        this.clubs = response.data

        for (const club of this.clubs) {
          this.clubStringOptions.push({ name: club.klubber_klubnavn, value: (club.id === undefined ? '0' : Number(club.id).toString()) })
          this.exportClubFilterOptions.push({ name: club.klubber_klubnavn, id: (club.id === undefined ? '0' : Number(club.id).toString()) })
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public exportDataToExcel () : void {
    console.log('Exporting JSON data to excel')
    let tempItem: any[] = []
    let tempArray: any[] = []
    let tempParameter = ''

    if (this.exportClubFilterValue.id !== '0') {
      tempParameter = 'klubber_id.id=' + this.exportClubFilterValue.id + '&' + this.exportStatusFilterValue.value
    } else {
      tempParameter = this.exportStatusFilterValue.value
    }

    if (this.exportRoleFilterValue !== 'Vis alt') {
      let id = ''
      switch (this.exportRoleFilterValue) {
        case 'Gæster':
          id = '1'
          break
        case 'Administratorer':
          id = '2'
          break
        case 'Klubansvarlige':
          id = '3'
          break
        case 'Holdkaptajn':
          id = '4'
          break
        case 'Klubmedlemmer':
          id = '5'
          break
        case 'Tilsynsførende':
          id = '6'
          break
        case 'Testgruppe12':
          id = '7'
          break
        default:
          break
      }

      if (id === '') {
        tempParameter = tempParameter + `&medlem_klubrolle=${this.exportRoleFilterValue}`
      } else {
        tempParameter = tempParameter + `&user_id.usrgroup=${id}`
      }
    }

    if (this.exportFieldsFilterValue.length > 0) {
      MembersDataService.getAll('', null, tempParameter)
        .then((response) => {
          this.exportMembers = response.data
          console.log(this.exportMembers)

          for (const filterItem of this.exportFieldsFilterValue) {
            if (filterItem.name === 'Navn') {
              const name = ['user_id', 'firstname', 'lastname']
              const surName = 'user_id.lastname'

              tempItem = this.exportMembers.map(o => {
                return {
                  [filterItem.id]: o[filterItem.id], [filterItem.name]: o[name[0]][name[1]] + ' ' + o[name[0]][name[2]]
                }
              })
            } else if (filterItem.name === 'Klubansvarlig' || filterItem.name === 'Holdkaptajn') {
              const clubManager = ['user_id', 'usrgroup']
              tempItem = this.exportMembers.map(o => {
                return {
                  [filterItem.id]: o[filterItem.id], [filterItem.name]: (o[clubManager[0]][clubManager[1]] === (filterItem.name === 'Klubansvarlig' ? defaultClubManagerGroupId : defaultClubCaptainGroupId) ? 'Ja' : '')
                }
              })
            } else if (filterItem.value.includes('.')) {
              const itemArray = filterItem.value.split('.')
              console.log(itemArray)
              tempItem = this.exportMembers.map(o => {
                return {
                  [filterItem.id]: o[filterItem.id], [filterItem.name]: (o[itemArray[0]] === null ? '' : o[itemArray[0]][itemArray[1]])
                }
              })
            } else {
              tempItem = this.exportMembers.map(o => {
                return {
                  [filterItem.id]: o[filterItem.id], [filterItem.name]: o[filterItem!.value]
                }
              })
            }
            if (tempArray.length === 0) {
              tempArray = tempItem
            } else {
              const arr3 = tempArray.map((item, i) => Object.assign({}, item, tempItem[i]))
              tempArray = arr3
            }
            console.log(tempArray)
          }

          const data = tempArray
          const fileName = 'medlemmer_ekport'
          const exportType = exportFromJSON.types.xls

          this.exportDropDown = false
          exportFromJSON({ data, fileName, exportType })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public editForeignPlayer (foreignId: number) : void {
    console.log('Edit foreignPlayer with Id: ' + foreignId)
    this.lastEditedForeignId = foreignId

    ForeignPlayersDataService.get(foreignId.toString())
      .then((response) => {
        this.tempForeignPlayer = response.data
        console.log(response.data)

        this.foreignPlayerData = {
          foreignPlayerStatus: this.tempForeignPlayer.ud_spiller_status,
          foreignPlayerGender: this.tempForeignPlayer.koen_id.Koen,
          foreignPlayerFirstName: this.tempForeignPlayer.ud_spiller_fornavn,
          foreignPlayerLastName: this.tempForeignPlayer.ud_spiller_efternavn,
          foreignPlayerCountry: this.tempForeignPlayer.ud_spiller_land,
          foreignPlayerParadart: this.tempForeignPlayer.ud_spiller_paradart,
          foreignPlayerBirthdate: this.tempForeignPlayer.ud_foedselsdag,
          foreignPlayerCreated: this.tempForeignPlayer.created_at,
          foreignPlayerUpdated: this.tempForeignPlayer.updated_at,
          foreignPlayerMail: (this.tempForeignPlayer.ud_spiller_mail === null ? '' : this.tempForeignPlayer.ud_spiller_mail)
        }

        this.editForeignPlayerModal = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public updateForeignPlayer () : boolean {
    console.log('Update information of foreign player with Id: ' + this.lastEditedForeignId)

    if (this.foreignPlayerData.foreignPlayerFirstName.length > 1 && this.foreignPlayerData.foreignPlayerLastName.length > 1 && this.foreignPlayerData.foreignPlayerCountry.length > 1 && this.foreignPlayerData.foreignPlayerBirthdate.length > 1 && this.foreignPlayerData.foreignPlayerGender !== 'Vælg her' && this.foreignPlayerData.foreignPlayerMail.length > 1 && BaseFunctions.isEmail(this.foreignPlayerData.foreignPlayerMail)) {
      const updateForeignPlayerData = {
        ud_spiller_status: true,
        koen_id: this.foreignPlayerData.foreignPlayerGender === 'Herre' ? 2 : 1,
        ud_foedselsdag: this.foreignPlayerData.foreignPlayerBirthdate,
        ud_spiller_fornavn: this.foreignPlayerData.foreignPlayerFirstName,
        ud_spiller_efternavn: this.foreignPlayerData.foreignPlayerLastName,
        ud_spiller_land: this.foreignPlayerData.foreignPlayerCountry,
        ud_spiller_paradart: this.foreignPlayerData.foreignPlayerParadart,
        ud_spiller_mail: this.foreignPlayerData.foreignPlayerMail
      }

      ForeignPlayersDataService.update(this.lastEditedForeignId.toString(), updateForeignPlayerData)
        .then((response) => {
          console.log(response.data)
          this.foreignPlayerData = { foreignPlayerStatus: true, foreignPlayerGender: 'Vælg her', foreignPlayerBirthdate: '', foreignPlayerFirstName: '', foreignPlayerLastName: '', foreignPlayerCountry: '', foreignPlayerParadart: false, foreignPlayerCreated: '', foreignPlayerUpdated: '', foreignPlayerMail: '' }
          this.lastEditedForeignId = 0
          this.retrieveForeignPlayers()
          this.editForeignPlayerModal = false
        })
        .catch((err) => {
          this.error = err
        })

      return true
    }
    return false
  }

  public cancelForeignPlayerEdit () : void {
    console.log('Cancel edit of foreign player')
    this.lastEditedForeignId = 0
    this.foreignPlayerData = { foreignPlayerStatus: true, foreignPlayerGender: 'Vælg her', foreignPlayerFirstName: '', foreignPlayerLastName: '', foreignPlayerCountry: '', foreignPlayerParadart: false, foreignPlayerBirthdate: '', foreignPlayerCreated: '', foreignPlayerUpdated: '', foreignPlayerMail: '' }
    this.editForeignPlayerModal = false
  }

  public retrieveForeignPlayers () : void {
    console.log('Counting foreign players')

    ForeignPlayersDataService.getCount(this.extraParameterForeign)
      .then((response) => {
        this.totalPagesForeign = response.data
        this.totalNumberOfPagesForeign = Math.ceil(this.totalPagesForeign / this.pageSizeValueForeign)
        console.log(this.currentPageForeign + ' of ' + this.totalNumberOfPagesForeign + ' - total number of entries: ' + this.totalPagesForeign)
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        if (this.foreignSearchTerm.length > 0) {
          ForeignPlayersDataService.findBySearchTerm(this.foreignSearchTerm, this.currentListSortingOrderForeign, { slicemode: 1, start: 0, limit: -1, page: (this.currentPageForeign - 1), pagesize: this.pageSizeValueForeign, totalcount: this.totalPagesForeign }, (this.extraParameterForeign))
            .then((response) => {
              this.foreignPlayers = response.data
              this.forceRerenderForeignPlayersList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
            })
        } else {
          ForeignPlayersDataService.getAll(this.currentListSortingOrderForeign, { slicemode: 1, start: 0, limit: -1, page: (this.currentPageForeign - 1), pagesize: this.pageSizeValueForeign, totalcount: this.totalPagesForeign }, this.extraParameterForeign)
            .then((response) => {
              this.foreignPlayers = response.data
              this.forceRerenderForeignPlayersList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
  }

  public deleteForeignPlayerWarning (playerId: number, playerName: string) : void {
    this.deleteForeignPlayerId = playerId
    this.deleteForeignPlayerName = playerName
    this.deleteForeignPlayerWarningModal = true
  }

  public deleteForeignPlayer (foreignPlayerId: number) : void {
    console.log('Deleting foreing player with Id: ' + foreignPlayerId)

    ForeignPlayersDataService.delete(foreignPlayerId.toString())
      .then((response) => {
        console.log(response.data)
        this.deleteForeignPlayerWarningModal = false
        this.retrieveForeignPlayers()
      })
      .catch((err) => {
        this.error = err
        this.deleteForeignPlayerWarningModal = false
      })
  }

  public createForeignPlayer () : void {
    console.log('Creating new foreing player')

    if (this.foreignPlayerData.foreignPlayerFirstName.length > 1 && this.foreignPlayerData.foreignPlayerLastName.length > 1 && this.countryStringValue !== 'Vælg her' && this.foreignPlayerData.foreignPlayerMail.length > 2 && BaseFunctions.isEmail(this.foreignPlayerData.foreignPlayerMail) && this.foreignPlayerData.foreignPlayerBirthdate.length > 0 && this.koenStringValueForeign !== 'Vælg her') {
      const createForeignPlayerData = {
        ud_spiller_status: true,
        koen_id: (this.koenStringValueForeign === 'Herre' ? 2 : 1),
        ud_foedselsdag: this.foreignPlayerData.foreignPlayerBirthdate,
        ud_spiller_fornavn: this.foreignPlayerData.foreignPlayerFirstName,
        ud_spiller_efternavn: this.foreignPlayerData.foreignPlayerLastName,
        ud_spiller_paradart: this.foreignPlayerData.foreignPlayerParadart,
        ud_spiller_mail: this.foreignPlayerData.foreignPlayerMail,
        ud_spiller_land: this.countryStringValue
      }

      ForeignPlayersDataService.create(createForeignPlayerData)
        .then((response) => {
          console.log(response.data)
          this.submitted = true
        })
        .catch((err) => {
          this.error = err
        })
        .then(() => {
          this.$Message.success({ text: 'Den undenlandske spiller er blevet oprettet' })
          this.newCreateForeignPlayer()
          this.tabValue = 'Vis og rediger udenlandske spillere'
        })
    }
  }

  public newCreateForeignPlayer () : void {
    this.submitted = false
    this.countryStringValue = 'Vælg her'
    this.koenStringValueForeign = 'Vælg her'
    this.foreignPlayerData = { foreignPlayerBirthdate: '', foreignPlayerCountry: '', foreignPlayerCreated: '', foreignPlayerGender: 'Vælg her', foreignPlayerMail: '', foreignPlayerFirstName: '', foreignPlayerLastName: '', foreignPlayerParadart: false, foreignPlayerStatus: true, foreignPlayerUpdated: '' }
    this.retrieveForeignPlayers()
  }

  public retrieveDate () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        // this.todaysDate = (new Date(response.data.split('T')[0])).toISOString().split('T')[0]
        this.todaysDate = (new Date(response.data)).toISOString().split('T')[0]
      })
      .catch((err) => {
        this.error = err
      })
  }
  // Onetime function to update all foreign player names
  // public splitForeignPlayersName () : void {
  //   let allForeignPlayers: SysForeignPlayer[] = []
  //   ForeignPlayersDataService.getAll('', null)
  //     .then((response) => {
  //       allForeignPlayers = response.data

  //       for (const foreignPlayer of allForeignPlayers) {
  //         const splitName = foreignPlayer.ud_spiller_navn.split(' ')

  //         const updateForeignPlayerData = {
  //           ud_spiller_fornavn: splitName[0].trim(),
  //           ud_spiller_efternavn: splitName[1].trim()
  //         }
  //         ForeignPlayersDataService.update(Number(foreignPlayer.id).toString(), updateForeignPlayerData)
  //           .then((response) => {
  //             console.log(response.data)
  //           })
  //           .catch((err) => {
  //             this.error = err
  //           })
  //       }
  //     })
  //     .catch((err) => {
  //       this.error = err
  //     })
  // }

  get clubRoleAndUserGroupOptions () : string[] {
    if (this.userGroupStringOptions.length > 0 && this.clubRoleFilterOptions.length > 0) {
      const optionsName = [...this.clubRoleFilterOptions]
      this.userGroupStringOptions.forEach((value, key) => {
        optionsName.push(value.name)
      })
      return [...new Set(optionsName)]
    } else {
      return []
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveMembers()
        this.retrieveClubs()
        this.retrieveUserGroups()
        this.convertClubRoleEnumToArray()
        this.retrieveForeignPlayers()
        this.retrieveDate()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
