
import { Options, Vue } from 'vue-class-component'
import AdminMembers from '@/components/Users/AdminMembers/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminMembers
  }
})
export default class MembersView extends Vue {}
